.App {
  background-color: white;
}

.full {
  width: 100%;
}

.flex {
  flex: 1;
}

.center {
  align-items: center;
  justify-content: center;
}

.textCenter {
  text-align: center;
}

.gx-app-login-wrap {
  background: white;
  backdrop-filter: blur(50px);
  height: 100vh;
  display: flex;
  flex-wrap: nowrap;
  border: 1px solid white;

}
.gx-app-login-content{
  display: flex;
  border: #0303f5;
  border-radius: 10px;
  overflow-x: hidden;

}
.login-form{
  backdrop-filter: blur(20px);
  background: linear-gradient(to top,#fcfcfc, #0A89CD ) ;
  padding: 20px;
  width: 50vh;

}

.btn-login{
  background-color: #00214E;
  font-weight: bold;
}

@media (max-width: 600px) {
  .ant-picker-panels {
    display: flex;
    flex-direction: column;
    height: 50;
  }

  .displayNone {
    display: none;
  }
}


@media (max-width: 1300px) {
  .sidebar-responsive {
    display: none;
  }
}

@media (min-width: 1300px) {
  .drawer-responsive {
    display: none;
  }
}

.loader {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 50px;
  aspect-ratio: 1;
  border-radius: 50%;
  padding: 3px;
  background:
    radial-gradient(farthest-side,#ffa516 95%,#0000) 50% 0/12px 12px no-repeat,
    radial-gradient(farthest-side,#0000 calc(100% - 5px),#ffa516 calc(100% - 4px)) content-box;
  animation: l6 2s infinite ;
}
@keyframes l6 {to{transform: rotate(1turn)}}

@keyframes move-above {
  from {
    transform: translateY(100px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.form-sign-in {
  animation: move-above 0.5s ease-in 0.1s 1 normal both;
}

